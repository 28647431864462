<template>
  <div class="animated">
    <CustomModal
      :modalTitle="$t('delete')"
      :button1Title="$t('delete')"
      button1Class="btn btn-danger"
      :show="removeModal"
      @hide="removeModal = false"
      @onButton1Click="remove()"
    >
      {{ $t("deleteConfirm") }}
    </CustomModal>
    <b-card no-body>
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("pages") }}
        <div class="card-header-actions">
          <BrandButton
            :text="$t('newPage')"
            @click="add()"
            v-if="$auth.hasAuth($options, 'add')"
          ></BrandButton>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col sm="6">
            <DateBetweenPicker
              :from="filters.fromCreatedAt"
              :to="filters.toCreatedAt"
              @change="dateChanged"
            ></DateBetweenPicker>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("pages") }}
      </b-card-header>
      <b-card-body>
        <v-server-table
          v-if="$auth.hasAuth($options, 'list')"
          :columns="columns"
          ref="table"
          :options="options"
          id="dataTable"
          class="dataGrid gridDetail"
        >
          <template slot="child_row" slot-scope="props">
            <div v-html="props.row.htmlContent" class="text-left p-3"></div>
          </template>
          <template slot="buttons" slot-scope="props">
            <div class="nowrap">
              <b-button
                variant="primary icon"
                v-b-tooltip.hover
                :title="$t('edit')"
                class="mr-1 btn-brand"
                v-if="$auth.hasAuth($options, 'edit')"
              >
                <i class="fa fa-edit" @click="edit(props.row.id)"></i>
              </b-button>
              <b-button
                variant="danger icon"
                v-b-tooltip.hover
                :title="$t('delete')"
                class="mr-1 btn-brand"
                v-if="$auth.hasAuth($options, 'remove')"
              >
                <i
                  class="fa fa-trash"
                  @click="showRemoveModal(props.row.id)"
                ></i>
              </b-button>
            </div>
          </template>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import gridOptions from "../../shared/grid-options";
import moment from "moment";
import http from "../../shared/http";
import { ServerTable } from "vue-tables-2";
import dateHelper from "../../shared/date-helper";
import utility from "../../shared/utils/screen-helper";
import toast from "../../shared/toast-helper";
import CustomModal from "../../widgets/form/CustomModal";
import DateBetweenPicker from "../../widgets/form/DateBetweenPicker";
import BooleanStateDropDown from "../../widgets/BooleanStateDropDown";
import toastHelper from "../../shared/toast-helper";
import BrandButton from "../../widgets/form/CustomBrandButton";

export default {
  name: "PageList",
  components: {
    ServerTable,
    CustomModal,
    BrandButton,
    DateBetweenPicker,
    BooleanStateDropDown,
  },
  methods: {
    getList() {
      this.$refs.table.setPage(1);
    },
    dateChanged(dates) {
      this.filters.fromCreatedAt = dates?.from ?? null;
      this.filters.toCreatedAt = dates?.to ?? null;
    },
    add() {
      this.$router.push({ path: "page-add" });
    },
    edit(id) {
      this.$router.push({ path: `page-add/${id}` });
    },
    showRemoveModal(id) {
      this.removeModal = true;
      this.selectedId = id;
    },
    remove() {
      http.post(`pages/delete?pageId=${this.selectedId}`).then((response) => {
        if (response.success) {
          this.getList();
          this.removeModal = false;
          this.selectedId = 0;
          toast.success(response.message);
        }
      });
    },
  },
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  data() {
    return {
      list: [],
      removeModal: false,
      selectedId: 0,
      filters: {
        fromCreatedAt: null,
        toCreatedAt: null,
      },
      columns: [
        "title",
        "url",
        "seoTitle",
        "seoKeywords",
        "createdAt",
        "buttons",
      ],
      options: {
        ...gridOptions.getAll(),
        pagination: { chunk: 10 },
        perPageValues: [10, 25, 50, 100],
        requestFunction: (data) => {
          let path = "pages/getlistbypaging";
          if (!data.orderBy) {
            data.orderBy = "createdAt";
            data.ascending = 0;
          }
          if (this.filters) {
            data.fromCreatedAt = this.filters.fromCreatedAt;
            data.toCreatedAt = this.filters.toCreatedAt;
          }

          return http
            .get(path, { params: data })
            .catch(function (e) {
              this.dispatch("error", e);
            })
            .finally(() => {
              utility.scrollToTop();
            });
        },
        sortable: "title,seoTitle,createdAt",
        headings: {
          title: this.$t("title"),
          url: this.$t("url"),
          seoTitle: this.$t("seoTitle"),
          createdAt: this.$t("createdAt"),
          seoKeywords: this.$t("seoKeywords"),
          buttons: this.$t("operations"),
        },
        templates: {
          createdAt: (h, row) =>
            moment(row.createdAt).format("DD/MM/YYYY HH:mm"),
        },
      },
    };
  },
};
</script>